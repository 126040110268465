html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  font-family: system-ui;
  margin: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

i{
    background-color: #e71e70;
    padding: 8%;
    border-radius: 5px;
    color: whitesmoke;
}

.item-feature{
    width: 100%;
    height: 100%;
    border-radius: 15px;
    box-shadow: 2px 5px 5px 5px darkgray;
    background-color: whitesmoke;
    opacity: .8;
}
.item-feature h3{
    font-size: 2em;
}
.item-feature p{
    font-size: 1.5em;
}
.section-title {
    margin-bottom: 50px;
    
  }

  
  .features .section-title {
    margin-bottom: 30px;
  }
  
  .features .single-feature {
    text-align: left;
    padding: 35px;
    background-color: #fff;
    border-radius: 6px;
    position: relative;
    margin-top: 30px;
    border: 1px solid #eee;
    -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  }
  
  .features .single-feature:hover {
    -webkit-box-shadow: 0px 3px 5px #00000017;
            box-shadow: 0px 3px 5px #00000017;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  
  .features .single-feature i {
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    display: inline-block;
    background-color:#e71e70;
    color: #fff;
    font-size: 20px;
    border-radius: 7px;
    -webkit-box-shadow: 0px 4px 6px #0000002a;
            box-shadow: 0px 4px 6px #0000002a;
    margin-bottom: 30px;
  }
  
  .features .single-feature h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .features .single-feature p {
    line-height: 22px;
  }


  @media screen and (max-width: 768px) {
    .item-feature h3{
      font-size: 1rem;
  }
  .item-feature p{
      font-size: 0.75rem;
  }
  .section-title {
      margin-bottom: 15px;
      
    }
    
    .features .section-title {
      margin-bottom: 0.75rem;
    }
    
    .features .single-feature i {
      
      font-size: 16px;
      
    }
    
    .features .single-feature h3 {
      font-size: 14px;
    
    }
  }
    
   

  
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .item-feature h3{
      font-size: 1.5rem;
  }
  .item-feature p{
      font-size: 1rem;
  }
  .section-title {
      margin-bottom: 15px;
      
    }
    
    .features .section-title {
      margin-bottom: 1rem;
    }
    
    .features .single-feature i {
      
      font-size: 16px;
      
    }
    
    .features .single-feature h3 {
      font-size: 14px;
     ;
    }
  }
  
  @media screen and (min-width: 1025px) {
    /* styles for screens larger than 1025px */
  }
  